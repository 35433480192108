import mixpanel from "mixpanel-browser";

export const mixpanelMixin = {
  methods: {
    async mixpanelTrack(eventName, eventData = "") {
      mixpanel.init(process.env.VUE_APP_MIXPANEL, { debug: false });

      if (eventData == "") {
        mixpanel.track(eventName);
      } else {
        mixpanel.track(eventName, eventData);
      }
    },
  },
};
